import { useNavigate } from 'react-router-dom';

import { ProductConfirmationPage } from '~pages/product-confirmation';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';

export default () => {
  const navigate = useNavigate();

  return (
    <ClientConfirmationGuardHOC>
      <ActiveApplicationGuardHOC
        onAccessDenied={() => navigate('/applications')}
      >
        <ProductConfirmationPage />
      </ActiveApplicationGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
