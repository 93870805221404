import { useNavigate, useSearchParams } from 'react-router-dom';

import { usePortalConfig } from '~app/providers/portal-config-provider';

import { SelectPos, ShowPosHelp } from '~features/select-pos';

import { BasicHeader } from '~shared/ui/basic-header';
import { Screen } from '~shared/ui/screen';

import Button from '@breeze-platform-ui/button';
import { useQueryClient } from '@tanstack/react-query';

export const SelectPosPage = () => {
  const { customerServicePhone } = usePortalConfig();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') || '/applications';

  const handleStoreSubmit = () => {
    queryClient.resetQueries(['agent-incentive']);
    navigate(`${redirectUrl}`);
  };

  return (
    <Screen
      header={
        <BasicHeader
          mainContent="Select your store"
          secondaryContent={
            <ShowPosHelp customerServicePhone={customerServicePhone} />
          }
        />
      }
      footer={
        <Button size="m" wide onClick={handleStoreSubmit}>
          Done
        </Button>
      }
    >
      <SelectPos />
    </Screen>
  );
};
