import { useState } from 'react';

import { environment } from '~app/environment';

import { CofirmClientWithItem } from '~features/confirm-client-with-item';
import { ConfirmDownpayment } from '~features/confirm-downpayment';

import {
  useConfirmDownpaymentMutation,
  useGetApplicationQuery,
} from '~entities/application';
import { useUploadFileMutation } from '~entities/file';

import { Screen } from '~shared/ui/screen';
import { Step, Stepper } from '~shared/ui/stepper';

import { Int24NavBack } from '@breeze-platform-ui/iconsPack';
import { Box } from '@breeze-platform-ui/layout';
import { FILE_STATUS, type FileItem } from '@breeze/rhf-adapters';

type DownpaymentConfirmationStep = 'CLIENT' | 'RECEIPT';

export const DownpaymentConfirmation: React.FC<{
  applicationId: string;
  onConfirmSuccess: () => void;
  onCancel: () => void;
}> = ({ applicationId, onConfirmSuccess, onCancel }) => {
  const shouldUploadClientWithItem =
    environment.CLIENT_PHOTO_WITH_ITEM_FEATURE === 'ON';
  const [step, setStep] = useState<DownpaymentConfirmationStep>(
    shouldUploadClientWithItem ? 'CLIENT' : 'RECEIPT'
  );
  const [clientPhoto, setClientPhoto] = useState<FileItem>();

  const { isLoading: isApplicationLoading, data: applicationData } =
    useGetApplicationQuery();
  const { isLoading: isConfirmationInProgress, mutate: confirm } =
    useConfirmDownpaymentMutation({
      onSuccess: onConfirmSuccess,
    });
  const { mutateAsync } = useUploadFileMutation();

  const handleBackClick = () => {
    if (!shouldUploadClientWithItem || step === 'CLIENT') {
      onCancel();
      return;
    }

    setStep('CLIENT');
  };

  const handleFileUpload = async (fileItem?: FileItem) => {
    if (!fileItem) {
      setClientPhoto(undefined);
      return;
    }

    setClientPhoto({
      file: fileItem.file,
      status: FILE_STATUS.LOADING,
    });

    try {
      await mutateAsync({
        applicationId,
        fileItem: {
          docType: 'CLIENT_PORTRAIT_WITH_ITEM',
          frontImage: fileItem.file,
        },
      });
      setClientPhoto({
        file: fileItem.file,
        status: FILE_STATUS.SUCCESS,
        previewUrl: URL.createObjectURL(fileItem.file),
      });
    } catch (err: any) {
      setClientPhoto({
        file: fileItem.file,
        status: FILE_STATUS.ERROR,
        message: err.message,
      });
    }
  };

  return (
    <Screen
      header={
        <Int24NavBack
          theme={{ color: 'var(--tds-color-accent)' }}
          cursor="pointer"
          onClick={handleBackClick}
        />
      }
    >
      <Box margin={16}>
        <Stepper current={step}>
          <Step name="CLIENT">
            <CofirmClientWithItem
              clientPhoto={clientPhoto}
              onPhotoUpdate={handleFileUpload}
              onPhotoSubmit={() => setStep('RECEIPT')}
            />
          </Step>
          <Step name="RECEIPT">
            <ConfirmDownpayment
              isApplicationLoading={isApplicationLoading}
              isConfirmationInProgress={isConfirmationInProgress}
              downPayment={applicationData?.approvedProduct?.downPayment}
              uploadFile={(file) =>
                mutateAsync({
                  applicationId,
                  fileItem: {
                    docType: 'RECEIPT',
                    frontImage: file.file,
                  },
                })
              }
              onConfirm={() => confirm(applicationId)}
            />
          </Step>
        </Stepper>
      </Box>
    </Screen>
  );
};
