import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type {
  AttachAttendanceFilePayload,
  AttendanceApi,
  AttendanceLogPayload,
  AttendanceLogResponse,
} from './attendance-api';

import type { AttendanceStatus } from '../attendance';

type Options = { host: string };

export class GatewayAttendanceApi implements AttendanceApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  async getAttendanceStatus(): Promise<{ status: AttendanceStatus }> {
    return this.makeRequest<{ status: AttendanceStatus }>({ method: 'status' });
  }

  async logAttendance(
    payload: AttendanceLogPayload
  ): Promise<AttendanceLogResponse> {
    return this.makeRequest({ method: 'log', payload });
  }

  async attachPhoto({ logId, file }: AttachAttendanceFilePayload) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('logId', logId);

    return this.makeRequest<void>({
      type: 'multipart/form-data',
      method: 'attach-photo',
      payload: formData,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'attendance',
        service: 'attendance',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayAttendanceApi = new GatewayAttendanceApi(webRequestClient, {
  host: environment.API_HOST,
});
