import React from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { MaskedInput, RadioGroup } from '@breeze/rhf-adapters';

import {
  additionalIdDocumentOptions,
  additionalIdDocumentMetaMap,
} from '../lib';
import type { AdditionalIdDocumentTypeValue } from '../lib';

export type FormValues = {
  type: AdditionalIdDocumentTypeValue;
  idNumber?: string;
};

type Props = {
  name: string;
  onSubmit: (formValues: FormValues) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
  initialValue?: Partial<FormValues>;
};

export const AdditionalIdDocument: React.FC<Props> = ({
  name,
  onSubmit,
  initialValue,
  onPrev,
  onFieldCompleted,
  onClose,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
  });
  const { control, watch, getValues, handleSubmit, resetField } = methods;

  useRevalidate(methods);

  const type = watch('type');
  const idDocumentMeta = additionalIdDocumentMetaMap[type];
  const showIdDocumentNumber = !!idDocumentMeta;

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <Col gaps={20} alignCross="stretch">
          <ScreenTitle title="Additional identity document" />
          <Text bold size={15}>
            These IDs increase approval rate by 5%
          </Text>
          <Controller
            control={control}
            name="type"
            render={(fieldProps) => (
              <RadioGroup
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onChange: (event, params) => {
                    resetField('idNumber');
                    fieldProps.field.onChange(event, params);
                  },
                }}
                qaLabel="additional-id-type-radio"
                options={additionalIdDocumentOptions}
                vertical
                wide
              />
            )}
          />
          {showIdDocumentNumber && (
            <>
              <Controller
                control={control}
                name="idNumber"
                shouldUnregister
                rules={idDocumentMeta.validators}
                render={(fieldProps) => (
                  <MaskedInput
                    {...fieldProps}
                    field={{
                      ...fieldProps.field,
                      // TODO: check why there can be null value after changing type
                      value: fieldProps.field.value || '',
                      onChange: (event, params) => {
                        fieldProps.field.onChange(
                          idDocumentMeta.transform(event, params)
                        );
                      },
                    }}
                    partialPlaceholder
                    label={idDocumentMeta.label}
                    mask={idDocumentMeta.mask.value}
                    maskTransitions={idDocumentMeta.mask.transitions}
                    placeholder={idDocumentMeta.placeholder}
                  />
                )}
              />
            </>
          )}
        </Col>
      </Screen>
    </form>
  );
};
