import { useNavigate } from 'react-router-dom';

import { LoanDetailsPage } from '~pages/loan-details';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';

export default () => {
  const navigate = useNavigate();

  return (
    <ClientConfirmationGuardHOC>
      <ActiveApplicationGuardHOC
        onAccessDenied={() => navigate('/applications')}
      >
        <LoanDetailsPage />
      </ActiveApplicationGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
