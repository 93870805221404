import { useNavigate } from 'react-router-dom';

import { DownpaymentConfirmationPage } from '~pages/downpayment-confirmation';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';

export default () => {
  const navigate = useNavigate();

  return (
    <ClientConfirmationGuardHOC>
      <ActiveApplicationGuardHOC onAccessDenied={() => navigate('../')}>
        <DownpaymentConfirmationPage />
      </ActiveApplicationGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
