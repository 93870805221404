import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';

import type { ClientInitiationNavigationState } from '~pages/client-initiation';

import { formatLoanCalculator } from '~widgets/loan-calculator';

import { useApplicationInitialMutation } from '~entities/application';
import type { ApplicationLoanDetails } from '~entities/application';
import { selectedPosAtom } from '~entities/pos';

import { useOnMountEffect } from '~shared/hooks';
import { BasicHeader } from '~shared/ui/basic-header';
import { NavCloseButton } from '~shared/ui/nav-close-button';
import { Screen } from '~shared/ui/screen';

import Button from '@breeze-platform-ui/button';
import { Col, Divider } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { useAtomValue } from 'jotai';

import styles from './mobile-self-app-redirect.module.css';

import qrSvg from '../images/qr.svg';

export const MobileSelfAppRedirectPage = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const posId = useAtomValue(selectedPosAtom)?.id;

  const { mutate: updateApplication } = useApplicationInitialMutation();
  const clientInitiationState =
    (state as ClientInitiationNavigationState) || {};

  useOnMountEffect(() => {
    const { loanCalculator, promoterCode } = clientInitiationState;
    if (loanCalculator) {
      const applicationLoanDetails: ApplicationLoanDetails =
        formatLoanCalculator(loanCalculator);
      updateApplication({
        posId,
        ...applicationLoanDetails,
        promoCode: promoterCode,
      });
    }
  });
  return (
    <Screen
      header={
        <BasicHeader actionButton={<NavCloseButton href="/applications" />} />
      }
      footer={
        <Col width="100%" alignCross="center">
          <Button wide onClick={() => navigate('/applications')}>
            Done
          </Button>
          <Divider color="transparent" margin={8} />
          <Text color="#9299A2">Customers can’t apply themselves?</Text>
          <Link
            to={`/loan-application/${id}`}
            state={state}
            className={styles.link}
          >
            Continue here
          </Link>
        </Col>
      }
    >
      <h1 className={styles.title}>
        Scan the&nbsp;QR code to download the&nbsp;Skyro app
      </h1>
      <img src={qrSvg} className={styles.qr} alt="Mobile app QR" />
    </Screen>
  );
};
