import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { ApplicationProcessStatus } from '~entities/application';

import { PopupCloseButton } from '~shared/ui/popup-close-button';
import { PopupTitle } from '~shared/ui/popup-title';

import Button from '@breeze-platform-ui/button';
import { ButtonLink } from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';
import { Col, Row } from '@breeze-platform-ui/layout';
import Sheet from '@breeze-platform-ui/sheet';
import Text from '@breeze-platform-ui/text';

import { ProcessStatusTitle } from '../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
};
export const SelfAppMobileOnboarding = ({ processStatus }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const onClosePopup = () => {
    setIsConfirmationOpened(false);
  };
  return (
    <>
      <Island
        theme="shadow"
        title={<ProcessStatusTitle processStatus={processStatus} />}
        body={
          <>
            If customers have problems applying in the app,{' '}
            <ButtonLink
              size="s"
              disableHorizontalPadding
              disableVerticalPadding
              onClick={() => setIsConfirmationOpened(true)}
            >
              create an application for them
            </ButtonLink>
          </>
        }
      />
      <Sheet
        mode="normal"
        modes={{
          collapsed: false,
          normal: {
            minHeight: 240,
            maxHeight: 440,
          },
          expanded: false,
        }}
        opened={isConfirmationOpened}
        onChange={() => {
          // do nothing
        }}
        onClose={onClosePopup}
      >
        <Col alignCross="stretch" dataQaType="change-flow-type-popup">
          <Row alignMain="between" alignCross="start">
            <PopupTitle title="Do you want to create a&nbsp;new application in the&nbsp;agent’s portal?" />
            <PopupCloseButton onClick={onClosePopup} />
          </Row>
          <Row margin={4}>
            <Text size={17}>
              Note that the application form from the customer’s app will be
              deleted
            </Text>
          </Row>

          <Row alignMain="between" gaps={12} margin="32px 0">
            <Button size="m" wide theme="secondary" onClick={onClosePopup}>
              No
            </Button>
            <Button
              size="m"
              wide
              theme="primary"
              onClick={() =>
                navigate(`/loan-application/${id}`, { replace: true })
              }
            >
              Yes
            </Button>
          </Row>
        </Col>
      </Sheet>
    </>
  );
};
