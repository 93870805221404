import { type PropsWithChildren, useCallback } from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { environment } from '~app/environment';
import { useOpeningTime } from '~app/providers/opening-time-provider';

import { AppLoadingPage } from '~widgets/app-loading-page';

import { isEmployeeRoleAllowedAtom, isAuthorizedAtom } from '~entities/auth';
import { selectedPosAtom } from '~entities/pos';

import { useAtomValue } from 'jotai';

export const AuthGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const openingTime = useOpeningTime();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoginPage = pathname.startsWith('/login');
  const isPosSelectPage = pathname.startsWith('/pos-select');
  const isAccessDeniedPage = pathname.startsWith('/access-denied');

  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const selectedPos = useAtomValue(selectedPosAtom);
  const hasAccess = useAtomValue(isEmployeeRoleAllowedAtom);

  const isPosSelected = selectedPos !== null;

  const shouldRedirectToLogin = !isAuthorized && !isLoginPage;

  const shouldRedirectToAccessDenied =
    isAuthorized && !hasAccess && !isAccessDeniedPage;

  // authorised agent doesn't have selected pos
  const shouldRedirectToPosSelect =
    isAuthorized &&
    hasAccess &&
    !isPosSelectPage &&
    !isLoginPage &&
    !isPosSelected;

  // agent was granted access while being on access denied page
  const shouldRedirectToMain =
    isAuthorized && hasAccess && (isLoginPage || isAccessDeniedPage);

  const shouldReloadPage = useCallback(
    () =>
      new Date().getTime() - openingTime >
      environment.USER_INACTIVE_THRESHOLD * 60 * 1000,
    [openingTime]
  );

  useEffect(() => {
    if (shouldRedirectToLogin) {
      if (shouldReloadPage()) {
        window.location.replace('/login');
      } else {
        navigate('/login');
      }

      return;
    }
    // invalid role
    if (shouldRedirectToAccessDenied) {
      navigate('/access-denied');
      return;
    }
    if (shouldRedirectToPosSelect) {
      if (shouldReloadPage()) {
        window.location.replace('/pos-select');
      } else {
        navigate('/pos-select');
      }
      return;
    }
    if (shouldRedirectToMain) {
      const mainPage =
        environment.ATTENDANCE_LOG_FEATURE === 'ON'
          ? '/account'
          : '/applications';
      if (shouldReloadPage()) {
        window.location.replace(mainPage);
      } else {
        navigate(mainPage);
      }
    }
  }, [
    shouldRedirectToLogin,
    shouldRedirectToAccessDenied,
    shouldRedirectToPosSelect,
    shouldRedirectToMain,
    shouldReloadPage,
    navigate,
  ]);

  const shouldRedirect =
    shouldRedirectToLogin ||
    shouldRedirectToAccessDenied ||
    shouldRedirectToPosSelect ||
    shouldRedirectToMain;

  if (shouldRedirect) {
    return <AppLoadingPage />;
  }

  return <>{children}</>;
};
