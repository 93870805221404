import { Controller, useForm } from 'react-hook-form';

import { FormFooter } from '~shared/ui/form-footer';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Col } from '@breeze-platform-ui/layout';
import { CheckboxGroup } from '@breeze/rhf-adapters';

import { type SurveyFormValues } from '../../types';

type CheckboxGroupQuestionProps = {
  question: string;
  options: string[];
  initialValue?: string[];
  isLastScreen: boolean;
  onSubmit: (values: SurveyFormValues) => void;
  onFieldCompleted?: (values: SurveyFormValues) => void;
  onPrev: (values: SurveyFormValues) => void;
};

export const CheckboxGroupQuestionForm = ({
  question,
  options,
  initialValue = [],
  isLastScreen,
  onFieldCompleted,
  onSubmit,
  onPrev,
}: CheckboxGroupQuestionProps) => {
  const methods = useForm<{ answer: string[] }>({
    mode: 'all',
    defaultValues: { answer: initialValue },
  });

  const handleBlur = () => {
    onFieldCompleted?.({ [question]: methods.getValues().answer });
  };

  const handleClickPrev = () => {
    onPrev({ [question]: methods.getValues().answer });
  };

  return (
    <Col alignCross="stretch">
      <form
        onBlur={handleBlur}
        onSubmit={methods.handleSubmit((values) =>
          onSubmit({ [question]: values.answer })
        )}
      >
        <ScreenTitle title={question} margin="0 0 16px" />
        <Controller
          name="answer"
          control={methods.control}
          render={(fieldProps) => (
            <CheckboxGroup
              {...fieldProps}
              options={options.map((option) => ({
                value: option,
                label: option,
              }))}
              vertical
              noWrapper
              qaLabel="question-checkbox-group"
            />
          )}
        />
        <FormFooter onClickPrev={handleClickPrev} isLastScreen={isLastScreen} />
      </form>
    </Col>
  );
};
