import { environment } from '../environment';

const enabledEmployeeIdsTest = new Set([
  // Agent phone number on test - +639211111115
  '5e5facde-7d90-465b-b80e-2ddc981beb08',
]);
const enabledEmployeeIdsProd: Set<string> = new Set([
  '816ad5f6-ce7e-4110-96b9-be0aeaa4d898',
  '95391c18-a714-40b7-89b6-cfdef6d4311d',
  'ab2cd790-006b-4a14-92f9-fe1fb2593dc0',
  'bdde16f5-8e8c-4fc6-83c1-4f3d3f44e119',
]);

export const isSelfApplicationFeatureEnabled = (employeeId: string) => {
  if (environment.ENV === 'prod') {
    return enabledEmployeeIdsProd.has(employeeId);
  }
  return enabledEmployeeIdsTest.has(employeeId);
};
