import { environment } from '~app/environment';

import type { RadioOption } from '~shared/types/radio-option';

const options = [
  {
    value: 'Client changed his/her mind',
    label: 'Customer changed his/her mind about taking a loan',
  },
  {
    value: 'Need to resubmit this application',
    label: 'Need to resubmit this application',
  },
  {
    value: 'Others',
    label: 'Others',
  },
] as const;

export type CancellationReasonValue = typeof options[number]['value'];
export const cancellationReasonOptions =
  options as unknown as RadioOption<CancellationReasonValue>[];

export const cancellationReasonCodeMap: Record<
  CancellationReasonValue,
  string
> = {
  'Client changed his/her mind': 'CLIENT_REJECT_WITH_EMPLOYEE',
  Others: 'CLIENT_REJECT_WITH_EMPLOYEE',
  'Need to resubmit this application': 'CLIENT_REJECT_WITH_EMPLOYEE_REAPPLY',
};

const merchantIds = [
  '01H0MPC7AZSTP60Y82CESR0DKM',
  '01H0PCEW85WP3G173CYKR81PEN',
  '01H0PJQH9KE2J81H97MKWF1T6B',
  '01H0PM6PEWW3GB001XMM5403SX',
  '01H0PRRG2YG29A0RSMFSSS01JT',
  '01H0PWNZQEVZZ5072FZA6Q0HP4',
  '01H0PXKTZAC6XA05XP00ZM1KK0',
  '01H0RVC4M1PR160ZDZH4FJ1BNS',
  '01H0RWDTXY1FPR0ZNBC7QP1Q2S',
  '01H0RYY5HQN00D0KTHMZJ31AHJ',
  '01H0S1Z6RGRWYF1VNYKK3N197N',
  '01H28YKECZ2JF00T75SVN814MS',
  '01H2HZJJH0SF3714Q1SRX61QE0',
  '01H2YHX0W5TZRZ1KPQYFVG1TB9',
  '01H31M76DG0M021FW0NZME0SMJ',
  '01H31MJC7RRG0T1Z68TB0H0GXD',
  '01H31N56ZQ0Q0D0557BM720AHG',
  '01H31NRJ668S090YC8TX4W161C',
  '01H31QFRVX4YDY1ZH4245Z1JMM',
  '01H31R0312Q8DC1XMB0JQ21YED',
  '01H31RNK39DZSC0NQ4KA5F0FXD',
  '01H31S0HEGGAPX10DD96001XKA',
  '01H31S9AGBZZ7S0PZCP70W18RB',
  '01H31SNFMKKZNA0JAZ8K8S1YA6',
  '01H31T05G8AQR40FMY9FMV1EMC',
  '01H31TC3WWX12Y1EGNRFYV09VY',
  '01HCBQY1KXR4SX0ZQQH4GG14GG',
  '01HGYWY63J90F71RQAE7YV14CA',
  '01HK59C8026PKR0ABGJFJV1KFA',
  '01HK59XM4SGA4J0YTDPBKX04XE',
  '01HK5AF22EAT0E1YAK93620241',
  '01HK5B0AJWMY6D1W1K030G0WS4',
  '01HK5BEH4Z2RCJ1JH4DXY800WZ',
  '01HNCKG7MX7XEH0D1Q7GCP1KNZ',
  '01J2XGF3EK88HA0C36SDEV08TP',
  '01J5QJ3D65M3Q90DTBC54P02QE',
  '01J5QJ8Z5MGYTE11GE7A7K0YC5',
  '01J5T93RR3XWA8105K45VD0PG4',
  '01J5QJRD5NX11K0RJ7CD980NC1',
  '01J5QJKAKJZC6C1FCFJCWH07TG',
  '01J69465WJMAPB1FX8FR441VZS',
];

const testIds = ['01GGENJCCZY27F1M9HD8JS0ZEW'];

export const getOptions = (
  merchantId: string
): RadioOption<CancellationReasonValue>[] => {
  const merchants = environment.ENV === 'prod' ? merchantIds : testIds;
  return merchants.includes(merchantId)
    ? cancellationReasonOptions
    : cancellationReasonOptions.filter(
        ({ value }) => value !== 'Need to resubmit this application'
      );
};
