import { useEffect } from 'react';

import { environment } from '~app/environment';

import { PendingNumber } from '~features/pending-number';

import {
  useGetAgentIncentiveQuery,
  isIncentiveProgramAvailableAtom,
} from '~entities/agent-incentive';
import { useGetNewsListQuery } from '~entities/agent-news';

import Badge from '@breeze-platform-ui/badge';
import { Col, Row } from '@breeze-platform-ui/layout';
import {
  TuiIconTdsMediumList,
  TuiIconTdsMediumUserImpersonal,
  TuiIconTdsMediumSpeaker,
  TuiIconTdsMediumLifebuoy,
} from '@tui-react/proprietary-icons';
import { useSetAtom } from 'jotai';

import { NavigationButton } from './navigation-button';
import styles from './navigation-footer.module.css';

const buttonContainerStyle = { flex: 1, flexBasis: '75px' };

export const NavigationFooter: React.FC = () => {
  // Request in the background to know if incentive program is available
  const { data } = useGetAgentIncentiveQuery({
    enabled: environment.AGENT_INCENTIVE_FEATURE === 'ON',
  });
  const { data: agentNews } = useGetNewsListQuery({
    enabled: environment.AGENT_NEWS_FEATURE === 'ON',
  });

  const setIncentiveAvailable = useSetAtom(isIncentiveProgramAvailableAtom);

  const isIncentiveAvailable = data && data.steps.length > 0;
  const attendanceLogOn = environment.ATTENDANCE_LOG_FEATURE === 'ON';

  useEffect(() => {
    if (isIncentiveAvailable) {
      setIncentiveAvailable(true);
    } else {
      setIncentiveAvailable(false);
    }
  }, [isIncentiveAvailable, setIncentiveAvailable]);

  return (
    <Row width="100%">
      {attendanceLogOn && (
        <Col alignCross="center" style={buttonContainerStyle}>
          <NavigationButton
            route="/account"
            Icon={TuiIconTdsMediumUserImpersonal}
            name="My account"
            isHome
          />
        </Col>
      )}
      <Col alignCross="center" style={buttonContainerStyle}>
        <NavigationButton
          route="/applications"
          Icon={TuiIconTdsMediumList}
          name="Applications"
          isHome={!attendanceLogOn}
          notification={
            <div className={styles.pendingAlert}>
              <PendingNumber />
            </div>
          }
        />
      </Col>
      {environment.AGENT_NEWS_FEATURE === 'ON' && (
        <Col alignCross="center" style={buttonContainerStyle}>
          <NavigationButton
            route="/agent-news"
            Icon={TuiIconTdsMediumSpeaker}
            name="News"
            notification={
              agentNews && agentNews.unreadCount > 0 ? (
                <div className={styles.newsAlert}>
                  <Badge customBackground="#F52222" size="m" padding={8}>
                    {agentNews.unreadCount}
                  </Badge>
                </div>
              ) : (
                <></>
              )
            }
          />
        </Col>
      )}
      {!attendanceLogOn && (
        <Col alignCross="center" style={buttonContainerStyle}>
          <NavigationButton
            route="/more"
            Icon={TuiIconTdsMediumLifebuoy}
            name="More"
          />
        </Col>
      )}
    </Row>
  );
};
