import type { PersonFormatter, PersonRecovery } from '~entities/person/lib';

import type { GCashDetailsFormValues } from '../types';

export const formatGCashDetails: PersonFormatter<GCashDetailsFormValues> = (
  person,
  screenValues
) => {
  return {
    ...person,
    metaData: { ...person.metaData, gCashScore: screenValues.gScore },
  };
};

export const recoverGCashDetails: PersonRecovery<GCashDetailsFormValues> = (
  person
) => {
  const { metaData } = person;

  if (!metaData?.gCashScore) {
    return undefined;
  }

  return { gScore: metaData.gCashScore };
};
