import { Controller, useForm } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { FormFooter } from '~shared/ui/form-footer';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Col } from '@breeze-platform-ui/layout';
import { RadioGroup } from '@breeze/rhf-adapters';

import { type SurveyFormValues } from '../../types';

type RadioGroupQuestionProps = {
  question: string;
  options: string[];
  initialValue: string;
  isLastScreen: boolean;
  onPrev: (values: SurveyFormValues) => void;
  onSubmit: (values: SurveyFormValues) => void;
  onFieldCompleted?: (values: SurveyFormValues) => void;
};

export const RadioGroupQuestionForm = ({
  question,
  options,
  initialValue,
  isLastScreen,
  onFieldCompleted,
  onSubmit,
  onPrev,
}: RadioGroupQuestionProps) => {
  const methods = useForm<{ answer: string }>({
    mode: 'all',
    defaultValues: { answer: initialValue },
  });
  useRevalidate(methods);

  const handleBlur = () => {
    onFieldCompleted?.({ [question]: methods.getValues().answer });
  };

  const handleClickPrev = () => {
    onPrev({ [question]: methods.getValues().answer });
  };

  return (
    <Col alignCross="stretch">
      <form
        onBlur={handleBlur}
        onSubmit={methods.handleSubmit((values) =>
          onSubmit({ [question]: values.answer })
        )}
      >
        <ScreenTitle title={question} margin="0 0 16px" />
        <Controller
          name="answer"
          control={methods.control}
          rules={{ required: 'Please, select an option' }}
          render={(fieldProps) => (
            <RadioGroup
              {...fieldProps}
              options={options.map((option) => ({
                value: option,
                label: option,
              }))}
              vertical
              noWrapper
              qaLabel="question-radio-group"
            />
          )}
        />
        <FormFooter onClickPrev={handleClickPrev} isLastScreen={isLastScreen} />
      </form>
    </Col>
  );
};
