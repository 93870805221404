import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '~app/providers/analytics-provider';

import {
  type Pos,
  PosItem,
  useGetPosListQuery,
  selectedPosAtom,
} from '~entities/pos';

import { Loader } from '~shared/ui/loader';

import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { useAtom } from 'jotai';

type NavigationState = {
  openedFrom?: 'more' | 'main';
};
export const SelectPos: React.FC = () => {
  const { state: navigationState } = useLocation();
  const analytics = useAnalytics();
  const {
    data: items,
    isLoading,
    isSuccess,
  } = useGetPosListQuery({ refetchOnMount: true });

  const [selectedPos, setSelectedPos] = useAtom(selectedPosAtom);

  const { openedFrom } = (navigationState as NavigationState) || {};

  useEffect(() => {
    if (!selectedPos && items && items.length > 0) {
      setSelectedPos(items[0]);
    }
  }, [items, selectedPos, setSelectedPos]);

  const handlePosSelect = (pos: Pos) => {
    openedFrom && analytics.trackPosChanged({ screen: openedFrom });
    setSelectedPos(pos);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess && selectedPos) {
    return (
      <Col gaps={24} alignCross="stretch">
        {items.map((pos) => (
          <PosItem
            key={pos.id}
            pos={pos}
            selected={selectedPos?.id === pos.id}
            onSelect={() => handlePosSelect(pos)}
          />
        ))}
      </Col>
    );
  }

  // TODO: replace with error screen(needs design)
  return (
    <Text size={15} align="center">
      Couldn’t load the&nbsp;stores. Contact your ASM for assistance.
    </Text>
  );
};
