import { type PropsWithChildren, useEffect } from 'react';

import { AppLoadingPage } from '~widgets/app-loading-page';

import {
  isAuthorizedAtom,
  isEmployeeRoleAllowedAtom,
  isSessionRestoredAtom,
} from '~entities/auth';
import { isPosInitializedAtom, useGetPosListQuery } from '~entities/pos';

import { useAtom, useAtomValue } from 'jotai';

export const PosInitializerWrapper = ({ children }: PropsWithChildren<{}>) => {
  const isSessionRestored = useAtomValue(isSessionRestoredAtom);
  const [isPosInitialized, setIsPosInitialized] = useAtom(isPosInitializedAtom);
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const hasAccess = useAtomValue(isEmployeeRoleAllowedAtom);

  const shouldCheckPos = !!isAuthorized && !!hasAccess;

  const posList = useGetPosListQuery({
    enabled: shouldCheckPos && !isPosInitialized,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (
      isSessionRestored &&
      !isPosInitialized &&
      (!shouldCheckPos || !posList.isLoading)
    ) {
      setIsPosInitialized(true);
    }
  }, [
    isSessionRestored,
    isPosInitialized,
    posList,
    setIsPosInitialized,
    shouldCheckPos,
  ]);

  if (shouldCheckPos && !isPosInitialized) {
    return <AppLoadingPage />;
  }

  return <>{children}</>;
};
