import { useNavigate } from 'react-router-dom';

import { ApplicationAgreementSigningPage } from '~pages/application-agreement-signing';

import {
  ActiveApplicationGuardHOC,
  ClientConfirmationGuardHOC,
} from '~features/auth/guard';

export default () => {
  const navigate = useNavigate();

  return (
    <ClientConfirmationGuardHOC>
      <ActiveApplicationGuardHOC onAccessDenied={() => navigate('../')}>
        <ApplicationAgreementSigningPage />
      </ActiveApplicationGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
