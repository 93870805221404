import type { ApplicationDetails } from '../application';

const isOnboarding = ({
  applicationStatus,
  formFillingStatus,
}: ApplicationDetails) =>
  applicationStatus === 'NEW' && formFillingStatus === 'IN_PROGRESS';

const isFormFillingFinished = (applicationDetails?: ApplicationDetails) =>
  applicationDetails?.applicationStatus === 'NEW' &&
  applicationDetails?.formFillingStatus === 'FINISHED';

export const isOldOnboarding = (applicationDetails: ApplicationDetails) =>
  !applicationDetails.selfApp && isOnboarding(applicationDetails);

export const isSelfAppMobileOnboarding = (
  applicationDetails: ApplicationDetails
) =>
  !!applicationDetails.selfApp &&
  applicationDetails.selfAppFlowType === 'MOBILE' &&
  !applicationDetails.selfAppPreApproved &&
  isOnboarding(applicationDetails);

export const isSelfAppAgentFlowOnboarding = (
  applicationDetails: ApplicationDetails
) =>
  !!applicationDetails.selfApp &&
  applicationDetails.selfAppFlowType === 'AGENT_PORTAL' &&
  isOnboarding(applicationDetails);

export const isPreApproved = (applicationDetails: ApplicationDetails) =>
  isOnboarding(applicationDetails) && !!applicationDetails.selfAppPreApproved;

export const isWaitingForDecision = (applicationDetails?: ApplicationDetails) =>
  isFormFillingFinished(applicationDetails) ||
  (applicationDetails?.applicationStatus === 'IN_PROGRESS' &&
    !applicationDetails?.verificationCompleted);

export const isMobileAppRequired = (applicationDetails?: ApplicationDetails) =>
  applicationDetails?.applicationStatus === 'IN_PROGRESS' &&
  applicationDetails.verificationCompleted &&
  !applicationDetails.mobileAppInstalled;

export const isReviewingCustomer = (applicationDetails?: ApplicationDetails) =>
  applicationDetails?.applicationStatus === 'IN_PROGRESS' &&
  applicationDetails.verificationCompleted &&
  applicationDetails.mobileAppInstalled;

export const isApproved = ({
  applicationStatus,
  downPaymentVerified,
  offerSigned,
}: ApplicationDetails) =>
  applicationStatus === 'APPROVED' &&
  downPaymentVerified === false &&
  offerSigned === false;

export const isRejected = ({
  applicationStatus,
  applicationCancelled,
}: ApplicationDetails) =>
  applicationStatus === 'REJECTED' && applicationCancelled === false;

export const isDownPaymentRequired = ({
  applicationStatus,
  downPaymentVerified,
  offerSigned,
}: ApplicationDetails) =>
  applicationStatus === 'APPROVED' &&
  downPaymentVerified === false &&
  offerSigned === true;

export const isAgreementCreated = ({ applicationStatus }: ApplicationDetails) =>
  applicationStatus === 'APPROVED';

export const isReleased = ({
  applicationStatus,
  downPaymentVerified,
  offerSigned,
}: ApplicationDetails) =>
  (applicationStatus === 'APPROVED' &&
    downPaymentVerified === true &&
    offerSigned === true) ||
  applicationStatus === 'FINISHED';

export const isCancelled = ({
  applicationStatus,
  applicationCancelled,
}: ApplicationDetails) =>
  applicationStatus === 'REJECTED' && applicationCancelled === true;

export const canCancel = (applicationDetails: ApplicationDetails) =>
  isWaitingForDecision(applicationDetails) ||
  isApproved(applicationDetails) ||
  isDownPaymentRequired(applicationDetails);

// Consider all applications with action required from the previous day and older pending
export const isDownpaymentPending = (application: ApplicationDetails) => {
  return (
    isDownPaymentRequired(application) &&
    new Date(application.lastUpdatedUTC).getTime() <
      new Date().setHours(0, 0, 0, 0)
  );
};

export const isActiveApplication = (application: ApplicationDetails) =>
  application.applicationStatus !== 'NEW' && !isReleased(application);
