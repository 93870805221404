import { useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { usePortalConfig } from '~app/providers/portal-config-provider';

import { SelectPos, ShowPosHelp } from '~features/select-pos';

import {
  AttendanceStatus,
  useAttachAttendancePhoto,
  useLogAttendance,
} from '~entities/attendance';
import { deviceLocation } from '~entities/device-metadata';
import { selectedPosAtom } from '~entities/pos';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { BasicHeader } from '~shared/ui/basic-header';
import { Screen } from '~shared/ui/screen';

import { LoadingButton } from '@breeze-platform-ui/button';
import { useNotifications } from '@pfa/front-notifications';
import { useAtomValue } from 'jotai';

export const AttendanceLogPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') ?? '/account';
  const { customerServicePhone } = usePortalConfig();

  const logAttendanceMutation = useLogAttendance();
  const attachPhotoMutation = useAttachAttendancePhoto();
  const attachRef = useRef<HTMLInputElement | null>(null);
  const selectedPos = useAtomValue(selectedPosAtom);
  const notifications = useNotifications();

  const handleAttachFileClick = () => {
    if (!selectedPos) {
      notifications.error('Please, select point of sale before taking selfie');
      return;
    }

    attachRef.current?.click();
  };

  const handleFileUpload = () => {
    const file = nonNullableValue(attachRef.current).files?.[0];
    if (!file) {
      notifications.error('Please, attach photo for attendance check');
      return;
    }

    // max file size is 5MB
    if (file.size > 5242880) {
      notifications.error(
        'Photo size should be under 5 MB. Please, reduce the photo resolution and upload it again'
      );
      return;
    }

    const geo = deviceLocation.getLocation();
    logAttendanceMutation.mutate(
      {
        type: AttendanceStatus.CheckIn,
        posId: nonNullableValue(selectedPos).id,
        location: {
          latitude: geo.deviceLatitude,
          longitude: geo.deviceLongitude,
        },
      },
      {
        onSuccess: ({ logId }) => {
          if (file) {
            attachPhotoMutation.mutate({ logId, file });
          }

          navigate(`${redirectUrl}`);
        },
        onError: () =>
          notifications.error('Something went wrong. Please, try again later'),
      }
    );
  };

  return (
    <Screen
      header={
        <BasicHeader
          mainContent="Select your store"
          secondaryContent={
            <ShowPosHelp customerServicePhone={customerServicePhone} />
          }
        />
      }
      footer={
        <>
          <LoadingButton
            wide
            size="m"
            onClick={handleAttachFileClick}
            loading={logAttendanceMutation.isLoading}
          >
            Take a selfie to confirm
          </LoadingButton>
          <input
            type="file"
            accept="image/*"
            capture="user"
            ref={attachRef}
            hidden
            onChange={handleFileUpload}
          />
        </>
      }
    >
      <SelectPos />
    </Screen>
  );
};
