import type { OrderItem } from '~entities/order-item';
import type { ZeroRateItem } from '~entities/product';
import type { AvailableItemInsurance } from '~entities/vas';

import type { LoanItemInfo } from '../types';

// Here we need to prepare the list of
// 1. all items that have available item vases (so that the user could select item vas for them on the loan details screen)
// 2. remaining zero rate items with brand producer (so that the agent could fill in manufacturer id for them)
// Prepared item should contain manufacturerIdType and vasList where possible
export const getLoanItemsDetailsList = (
  orderItems: OrderItem[],
  vasList: AvailableItemInsurance[],
  zeroRateItems: Record<string, ZeroRateItem>
): LoanItemInfo[] => {
  const listWithVas: LoanItemInfo[] = [];
  const remainingZeroRateList: LoanItemInfo[] = [];

  orderItems.forEach((item) => {
    const availableItemVas = vasList.find(
      ({ name, category }) => name === item.name && category === item.category
    );
    const isNonRetailerZeroRateItem =
      item.zeroRateItemId &&
      zeroRateItems[item.zeroRateItemId]?.producerType !== 'RETAILER';
    if (availableItemVas || isNonRetailerZeroRateItem) {
      for (let i = 0; i < item.quantity; i++) {
        const array = availableItemVas ? listWithVas : remainingZeroRateList;
        array.push({
          name: item.name,
          category: item.category,
          price: item.price,
          zeroRateItemId: item.zeroRateItemId,
          type: item.zeroRateItemId
            ? zeroRateItems[item.zeroRateItemId]?.manufacturerIdType
            : undefined,
          vasList: availableItemVas?.vasList || [],
          producerType: item.zeroRateItemId
            ? zeroRateItems[item.zeroRateItemId]?.producerType
            : undefined,
        });
      }
    }
  });

  return [...listWithVas, ...remainingZeroRateList];
};
