import type { Pos } from '~entities/pos';

export enum WorkDayType {
  REST_DAY = 'RD',
  FULL_DAY = 'FD',
  HALF_DAY = 'HD',
  HALF_DAY_LEAVE = 'HDL',
  OVERTIME_WORK = 'RDOT',
}

export type WorkDay = {
  type: WorkDayType;
  posList: Pos[];
};
