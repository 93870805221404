import { useNavigate } from 'react-router-dom';

import { LoanApplicationPage } from '~pages/loan-application';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';

export default () => {
  const navigate = useNavigate();
  return (
    <ClientConfirmationGuardHOC>
      <ActiveApplicationGuardHOC
        onAccessDenied={() => navigate('/applications')}
      >
        <LoanApplicationPage />
      </ActiveApplicationGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
