import { useNavigate, useParams } from 'react-router-dom';

import { ApplicationAgreementSingning } from '~widgets/application-agreement-singning';

import { useGetApplicationQuery } from '~entities/application';
import type { ApplicationDetails } from '~entities/application';
import { selectedPosAtom } from '~entities/pos';

import { FormHeader } from '~shared/ui/form-header';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';
import { ScrollIntoView } from '~shared/ui/scroll-into-view';

import Text from '@breeze-platform-ui/text';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const ApplicationAgreementSigningPage = () => {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const selectedPos = useAtomValue(selectedPosAtom);
  const { isLoading, isSuccess, isError, data } = useGetApplicationQuery();

  const handleApplicationSigned = () => {
    const applicationData = queryClient.getQueryData<ApplicationDetails>([
      'applications',
      id,
    ]);
    if (applicationData) {
      const updatedApplication: ApplicationDetails = {
        ...applicationData,
        offerSigned: true,
      };
      const applicationsList = queryClient.getQueryData<ApplicationDetails[]>([
        'applications',
        'list',
        selectedPos?.id,
      ]);
      if (applicationsList?.length) {
        queryClient.setQueryData<ApplicationDetails[]>(
          ['applications', 'list', selectedPos?.id],
          applicationsList.map((application) =>
            application.id === id ? updatedApplication : application
          )
        );
      }
      queryClient.setQueryData<ApplicationDetails>(
        ['applications', id],
        updatedApplication
      );
    }

    setTimeout(() => navigate(`/applications/${id}`, { replace: true }), 500);
  };

  return (
    <Screen
      header={
        <FormHeader
          href={`/applications/${id}/`}
          text="Enter the&nbsp;code to&nbsp;sign the&nbsp;agreement"
        />
      }
    >
      <ScrollIntoView>
        {isLoading && <Loader />}
        {isError && (
          <Text size={15} align="center">
            Couldn’t load the&nbsp;application info
          </Text>
        )}
        {isSuccess && (
          <ApplicationAgreementSingning
            applicationId={id}
            phoneNumber={data.person.mobilePhone}
            onSuccess={handleApplicationSigned}
          />
        )}
      </ScrollIntoView>
    </Screen>
  );
};
