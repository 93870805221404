import {
  useGetApplicationByIdQuery,
  type ApplicationDetails,
} from '~entities/application';
import { type ZeroRateItem, useGetZeroRateItems } from '~entities/product';
import {
  useGetAvailableVasListQuery,
  useGetApplicationVasListQuery,
} from '~entities/vas';
import type { AvailableVasList, ApplicationVasList } from '~entities/vas';

import { nonNullableValue } from '~shared/types/non-nullable-value';

type LoanDetailsInitialData = {
  availableVasList: AvailableVasList; // All available vas products
  applicationVasList: ApplicationVasList; // Selected vas products for the application
  applicationDetails: ApplicationDetails; // Approved loan amount and period
  zeroRateItems: Record<string, ZeroRateItem>;
};

type VasInitialData = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data?: LoanDetailsInitialData;
  refetchFailedRequests?: () => void;
};

export const useInitialData = (applicationId: string): VasInitialData => {
  const zeroRateItems = useGetZeroRateItems();
  const applicationDetails = useGetApplicationByIdQuery(applicationId);
  const applicationVasList = useGetApplicationVasListQuery(applicationId);

  const availableVasList = useGetAvailableVasListQuery(applicationId, {
    enabled: applicationDetails.isSuccess,
  });

  const initializeQueries = [
    applicationDetails,
    applicationVasList,
    availableVasList,
    zeroRateItems,
  ];

  const failedQueries = initializeQueries.filter((query) => query.isError);
  const isError = failedQueries.length > 0;
  const isSuccess = initializeQueries.every((query) => query.isSuccess);

  if (isError) {
    return {
      isError: true,
      isLoading: false,
      isSuccess: false,
      refetchFailedRequests: () => {
        failedQueries.forEach((query) => {
          query.refetch();
        });
      },
    };
  }

  if (isSuccess) {
    const vasData: LoanDetailsInitialData = {
      availableVasList: nonNullableValue(availableVasList.data),
      applicationVasList: nonNullableValue(applicationVasList.data),
      applicationDetails: nonNullableValue(applicationDetails.data),
      zeroRateItems: nonNullableValue(zeroRateItems.data),
    };
    return {
      isLoading: false,
      isError: false,
      isSuccess: true,
      data: vasData,
    };
  }
  return {
    isLoading: true,
    isError: false,
    isSuccess: false,
  };
};
