import { useForm, type FieldErrors, Controller } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { RadioGroup } from '@breeze/rhf-adapters';

import type { ClientPhoneAssessmentValue } from '../lib/client-phone-assessment-options';
import { clientPhoneAssessmentOptions } from '../lib/client-phone-assessment-options';

export type FormValues = {
  canInstallApp: ClientPhoneAssessmentValue;
};

type Props = Readonly<{
  name: string;
  initialValue?: Partial<FormValues>;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (formValues: FormValues) => void;
  onFieldCompleted?: (
    value: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
}>;

export const ClientPhoneAssessment: React.FC<Props> = ({
  name,
  initialValue,
  onSubmit,
  onPrev,
  onClose,
  onFieldCompleted,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
    shouldUnregister: true,
  });
  const { control, getValues, handleSubmit } = methods;

  useRevalidate(methods);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleOnPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form
      onBlur={handleBlur}
      onSubmit={handleSubmit((formValues) => onSubmit(formValues))}
    >
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleOnPrev} />}
      >
        <ScreenTitle title="Can the customer install the&nbsp;Skyro app on&nbsp;their phone?" />
        <Controller
          control={control}
          name="canInstallApp"
          rules={{ required: 'Please, select an option' }}
          render={(fieldProps) => (
            <RadioGroup
              {...fieldProps}
              qaLabel="can-install-app-radio"
              wide
              vertical
              options={clientPhoneAssessmentOptions}
            />
          )}
        />
      </Screen>
    </form>
  );
};
