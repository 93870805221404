import { useNavigate } from 'react-router-dom';

import { SelfApplicationAgentPage } from '~pages/self-application-agent';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';

export default () => {
  const navigate = useNavigate();
  return (
    <ClientConfirmationGuardHOC>
      <ActiveApplicationGuardHOC
        onAccessDenied={() => navigate('/applications')}
      >
        <SelfApplicationAgentPage />
      </ActiveApplicationGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
