import React from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { Col } from '@breeze-platform-ui/layout';
import {
  CheckboxGroup,
  InputMoney,
  Input,
  Select,
  Multiselect,
} from '@breeze/rhf-adapters';

import { type EmploymentStatusValue } from '../../employment/lib';
import {
  MONTHLY_INCOME_REQUIRED_ERROR,
  MONTHLY_PAYMENT_REQUIRED_ERROR,
  SELECT_REQUIRED_ERROR,
  MULTI_SELECT_REQUIRED_ERROR,
} from '../constants/field-errors';
import {
  DEBT_INFO_QA_LABEL,
  FINANCIAL_SITUATION_FIELD_NAME,
  MONTHLY_INCOME_FIELD_NAME,
  SOURCE_OF_FUNDS_FIELD_NAME,
  MONTHLY_PAYMENT_FIELD_NAME,
} from '../constants/field-names';
import type { BooleanFinanceFieldsValue } from '../lib/boolean-finance-fields';
import type { FinancialSituationValue } from '../lib/financial-situation-options';
import { financialSituationOptions } from '../lib/financial-situation-options';
import { getFinancesDetailsOptions } from '../lib/get-finances-fetails-options';
import type { IncomeSourceValue } from '../lib/income-source-options';
import { incomeSourceOptions } from '../lib/income-source-options';

export interface FormValues {
  financialSituation: FinancialSituationValue;
  personMonthlyIncome: string;
  debtInfo?: BooleanFinanceFieldsValue[];
  monthlyPaymentAmount?: string;
  incomeSources: IncomeSourceValue[];
  incomeSourcesOther?: string;
}

type Props = Readonly<{
  name: string;
  initialValue?: Partial<FormValues>;
  employmentStatus: EmploymentStatusValue;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (values: FormValues) => void;
  onFieldCompleted?: (
    value: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
}>;

export const Finances: React.FC<Props> = ({
  name,
  onSubmit,
  employmentStatus,
  initialValue,
  onPrev,
  onFieldCompleted,
  onClose,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
    shouldUnregister: true,
  });
  const { control, watch, getValues, handleSubmit } = methods;

  useRevalidate(methods);

  const incomeSources = watch('incomeSources') ?? [];
  const debtInfo = watch('debtInfo') ?? [];

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const hasOutstandingDebt = debtInfo.includes('haveOutstandingDebtNow');
  const hasOtherIncomeSources = incomeSources.includes('OTHER');

  const financesDetailsOptions = getFinancesDetailsOptions(debtInfo);

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <Col gaps={32} alignCross="stretch">
          <ScreenTitle title="Finances" />
          <Controller
            control={control}
            name="financialSituation"
            rules={{ required: SELECT_REQUIRED_ERROR }}
            render={(fieldProps) => (
              <Select
                {...fieldProps}
                options={financialSituationOptions}
                label={FINANCIAL_SITUATION_FIELD_NAME}
              />
            )}
          />
          <Controller
            control={control}
            name="personMonthlyIncome"
            rules={{
              required: MONTHLY_INCOME_REQUIRED_ERROR,
            }}
            render={(fieldProps) => (
              <InputMoney
                {...fieldProps}
                label={MONTHLY_INCOME_FIELD_NAME}
                currency="PHP"
                currencyInValue
                symbolPosition="prefix"
                digitGroupingSeparator=","
                maxLength={11} // Includes digitGroupingSeparator
                message="Salary, remittances, renting out housing, etc."
              />
            )}
          />
          {employmentStatus === 'UNEMPLOYED' && (
            <>
              <Controller
                control={control}
                name="incomeSources"
                rules={{
                  required: MULTI_SELECT_REQUIRED_ERROR,
                }}
                render={(fieldProps) => (
                  <Multiselect
                    {...fieldProps}
                    options={incomeSourceOptions}
                    label={SOURCE_OF_FUNDS_FIELD_NAME}
                  />
                )}
              />
              {hasOtherIncomeSources && (
                <Controller
                  control={control}
                  shouldUnregister
                  name="incomeSourcesOther"
                  rules={{
                    validate: combineValidators(
                      validators.required({
                        text: 'Enter customer’s source of funds',
                      }),
                      validators.maxLength({ maxLength: 150 })
                    ),
                  }}
                  render={(fieldProps) => (
                    <Input {...fieldProps} label="Source of funds (other)" />
                  )}
                />
              )}
            </>
          )}

          <Controller
            control={control}
            name="debtInfo"
            render={(fieldProps) => (
              <CheckboxGroup
                {...fieldProps}
                qaLabel={DEBT_INFO_QA_LABEL}
                field={{
                  ...fieldProps.field,
                  onChange(values: BooleanFinanceFieldsValue[]) {
                    fieldProps.field.onChange(
                      values.includes('creditUsage')
                        ? values
                        : values.filter((value) => value === 'haveBankAccount')
                    );
                  },
                }}
                wide
                vertical
                options={financesDetailsOptions}
              />
            )}
          />
          {hasOutstandingDebt && (
            <Controller
              control={control}
              name="monthlyPaymentAmount"
              shouldUnregister
              rules={{
                required: MONTHLY_PAYMENT_REQUIRED_ERROR,
              }}
              render={(fieldProps) => (
                <InputMoney
                  maxLength={11} // Includes digitGroupingSeparator
                  label={MONTHLY_PAYMENT_FIELD_NAME}
                  currency="PHP"
                  currencyInValue
                  symbolPosition="prefix"
                  digitGroupingSeparator=","
                  {...fieldProps}
                />
              )}
            />
          )}
        </Col>
      </Screen>
    </form>
  );
};
