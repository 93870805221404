import type { OrderItem } from '~entities/order-item';
import { OrderItemList } from '~entities/order-item';

import { SectionTitle } from '~shared/ui/section-title';

type Props = { items: OrderItem[] };

export const OrderItems = ({ items }: Props) => {
  const groupedItems: OrderItem[] = [];
  items.forEach((item) => {
    const grouped = groupedItems.find(
      (groupedItem) =>
        groupedItem.name === item.name &&
        groupedItem.category === item.category &&
        groupedItem.price === item.price
    );
    if (grouped) {
      grouped.quantity += 1;
    } else {
      groupedItems.push({ ...item });
    }
  });
  return (
    <div>
      <SectionTitle title="Items to&nbsp;buy" margin="0 0 16px" padding={0} />
      <OrderItemList items={groupedItems} />
    </div>
  );
};
