export enum AttendanceStatus {
  CheckIn = 'CHECK_IN',
  CheckOut = 'CHECK_OUT',
  LunchIn = 'LUNCH_IN',
  LunchOut = 'LUNCH_OUT',
  CommuteIn = 'COMMUTE_IN',
  CommuteOut = 'COMMUTE_OUT',
  BreakIn = 'BREAK_IN',
  BreakOut = 'BREAK_OUT',
}
