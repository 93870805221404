import { useMutation, useQueryClient } from '@tanstack/react-query';

import type {
  AttachAttendanceFilePayload,
  AttendanceLogPayload,
} from './attendance-api';
import { attendanceKeys } from './attendance-keys';
import { gatewayAttendanceApi } from './gateway-attendance-api';

export function useLogAttendance() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: attendanceKeys.log(),
    mutationFn: async (payload: AttendanceLogPayload) => {
      const result = await gatewayAttendanceApi.logAttendance(payload);

      queryClient.setQueryData(attendanceKeys.status(), {
        status: payload.type,
      });

      return result;
    },
    retry: 3,
  });
}

export function useAttachAttendancePhoto() {
  return useMutation({
    mutationKey: attendanceKeys.attach(),
    mutationFn: async (payload: AttachAttendanceFilePayload) => {
      return gatewayAttendanceApi.attachPhoto(payload);
    },
  });
}
