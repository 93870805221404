import { useNavigate } from 'react-router-dom';

import { MobileSelfAppRedirectPage } from '~pages/mobile-self-app-redirect';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';

export default () => {
  const navigate = useNavigate();
  return (
    <ClientConfirmationGuardHOC>
      <ActiveApplicationGuardHOC
        onAccessDenied={() => navigate('/applications')}
      >
        <MobileSelfAppRedirectPage />
      </ActiveApplicationGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
