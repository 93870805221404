import { useState } from 'react';
import { useForm, type FieldErrors, Controller } from 'react-hook-form';

import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { RadioGroup } from '@breeze/rhf-adapters';

import { REQUIRED_TYPE_ERROR } from '../constants/field-errors';
import type {
  MainIdDocumentTypeValue,
  OtherIdDocumentTypeValue,
} from '../lib/id-document-type-options';
import {
  idDocumentTypeMainOptions,
  idDocumentTypeOtherOptions,
} from '../lib/id-document-type-options';

export type FormValues = {
  typeMain?: MainIdDocumentTypeValue | null;
  typeOther?: OtherIdDocumentTypeValue | null;
};

type Props = {
  name: string;
  onSubmit: (formValues: FormValues) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
  initialValue?: Partial<FormValues>;
};

export const IdDocumentType: React.FC<Props> = ({
  initialValue,
  name,
  onFieldCompleted,
  onClose,
  onSubmit,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
  });
  const { control, getValues, handleSubmit, resetField, watch } = methods;
  const [showOtherTypes, setShowOtherTypes] = useState<boolean>(
    () => !!initialValue?.typeOther
  );

  useRevalidate(methods);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleFormSubmit = (value: FormValues) => {
    onSubmit(value);
  };

  const handleShowOtherDocsClick = () => {
    resetField('typeMain', { defaultValue: null });
    setShowOtherTypes(true);
  };

  const typeMain = watch('typeMain');

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(handleFormSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter />}
      >
        <Col gaps={20} alignCross="stretch">
          <ScreenTitle title="Main identity document" />
          <Text bold size={15}>
            These IDs increase approval rate by 7%
          </Text>
          <Controller
            control={control}
            name="typeMain"
            rules={{
              required: showOtherTypes ? undefined : REQUIRED_TYPE_ERROR,
            }}
            render={(fieldProps) => (
              <RadioGroup
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onChange: (event, params) => {
                    methods.resetField('typeOther', { defaultValue: null });
                    fieldProps.field.onChange(event, params);
                  },
                }}
                qaLabel="mainIdType"
                options={idDocumentTypeMainOptions}
                vertical
                wide
              />
            )}
          />
          {!showOtherTypes && (
            <Button theme="outline" size="l" onClick={handleShowOtherDocsClick}>
              Customer doesn’t have these IDs
            </Button>
          )}
          {showOtherTypes && (
            <>
              <Text bold size={15}>
                Other IDs
              </Text>
              <Controller
                control={control}
                name="typeOther"
                rules={{
                  validate: (typeOther) =>
                    showOtherTypes && !typeOther && !typeMain
                      ? REQUIRED_TYPE_ERROR
                      : undefined,
                }}
                render={(fieldProps) => (
                  <RadioGroup
                    {...fieldProps}
                    field={{
                      ...fieldProps.field,
                      onChange: (event, params) => {
                        methods.resetField('typeMain', { defaultValue: null });
                        fieldProps.field.onChange(event, params);
                      },
                    }}
                    qaLabel="otherIdType"
                    options={idDocumentTypeOtherOptions}
                    vertical
                    wide
                  />
                )}
              />
            </>
          )}
        </Col>
      </Screen>
    </form>
  );
};
