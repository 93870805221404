import { usePortalConfig } from '~app/providers/portal-config-provider';

import { AgentHelp } from '~widgets/agent-help';

import { selectedPosAtom } from '~entities/pos';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { NavigationFooter } from '~shared/ui/navigation-footer';
import { Screen } from '~shared/ui/screen';

import { useAtomValue } from 'jotai';

export const AgentHelpPage = () => {
  const currentPosName = useAtomValue(selectedPosAtom)?.name;
  const { customerServicePhone } = usePortalConfig();

  return (
    <Screen header={<HeaderWithLogo />} footer={<NavigationFooter />}>
      <AgentHelp
        currentPos={nonNullableValue(currentPosName)}
        customerServicePhone={customerServicePhone}
      />
    </Screen>
  );
};
