import { useState } from 'react';

import { environment } from '~app/environment';

import { ApplicationProcessStatusDetails } from '~features/application-process-status';

import type { ApplicationDetails as Details } from '~entities/application';
import { canCancel, isAgreementCreated } from '~entities/application';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';

import { ApplicationCancelPopup } from './application-cancel-popup';
import { OrderItems } from './order-items';
import { StoreInfo } from './store-info';
import { UserInfo } from './user-info';

type Props = {
  application: Details;
};

export const ApplicationDetails = ({ application }: Props) => {
  const [isCancelPopupOpened, setIsCancelPopupOpened] = useState(false);
  const hasCancelApplicationFeature =
    environment.APPLICATION_CANCEL_FEATURE === 'ON';
  const agreementId =
    (isAgreementCreated(application) && application.agreementId) || undefined;

  return (
    <Col gaps="xl" alignCross="stretch">
      <UserInfo
        name={application.person.fullName || ''}
        phoneNumber={application.person.mobilePhone}
        agreementId={agreementId}
      />
      <ApplicationProcessStatusDetails applicationDetails={application} />
      {application.orderItems && <OrderItems items={application.orderItems} />}
      <StoreInfo posId={application.posId} />
      {hasCancelApplicationFeature && canCancel(application) && (
        <Button
          wide
          size="m"
          theme="secondary"
          onClick={() => setIsCancelPopupOpened(true)}
        >
          Cancel application
        </Button>
      )}
      {hasCancelApplicationFeature && (
        <ApplicationCancelPopup
          applicationId={application.id}
          isOpened={isCancelPopupOpened}
          onClose={() => setIsCancelPopupOpened(false)}
        />
      )}
    </Col>
  );
};
